@import '../node_modules/@sodra/bongo-ui/dark.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background: var(--surface);
  color: var(--on-surface);
}

* {
  box-sizing: border-box;
}

.bui {
  --hue: 140;
  --saturation: 85%;
  --lightness: 36%;

  --background: hsl(var(--hue) 1% 7%);
  --surface: hsl(var(--hue) 1% 7%);
  --surface-floating: hsl(var(--hue) 1% 11%);
  --container-background: hsla(var(--hue), 5%, 16%, 0.8);
  --container-outline: hsla(var(--hue), 5%, 26%, 0.8);
  --container-outline-lighter: hsla(var(--hue), 5%, 23%, 0.8);
  --data-table-hover: hsl(var(--hue), 5%, 13%); /* Needs to be opaque */

  --inverted-surface: hsl(var(--hue) 10% 20%);
  --surface-alternative: hsl(var(--hue) 5% 13%);

  --accent-highlight: rgba(255, 255, 255, 0.75);
  --surface-highlight: hsl(var(--hue) 30% 30%);

  --accent-surface: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 0.835483871));
  --accent-light: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 0.55));
  --selection-control: hsl(var(--hue) 10% 36%);
  --error: hsl(var(--error-hue) var(--saturation) 46%);
  --error-text: hsl(var(--error-hue) var(--saturation) 54%);
  --ok-text: hsl(var(--ok-hue) calc(var(--saturation) * 0.38) 45%);
  --warning-text: hsl(var(--warning-hue) calc(var(--saturation) * 0.7) 48%);
}
